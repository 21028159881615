import { Link } from 'gatsby';
import React, { FC } from 'react';

const NotFoundPage: FC = () => (
  <main>
    <title>Not found</title>
    <h1>Page not found</h1>
    <p>
      Sorry{' '}
      <span aria-label="Pensive emoji" role="img">
        😔
      </span>{' '}
      we couldn’t find what you were looking for.
      <br />
      {'development' === process.env.NODE_ENV ? (
        <>
          <br />
          Try creating a page in <code>src/pages/</code>.
          <br />
        </>
      ) : null}
      <br />
      <Link to="/">Go home</Link>.
    </p>
  </main>
);

export default NotFoundPage;
